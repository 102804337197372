// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-aerospace-and-defense-component-manufacturing-js": () => import("./../../../src/pages/industry/aerospace-and-defense-component-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-aerospace-and-defense-component-manufacturing-js" */),
  "component---src-pages-industry-automotive-component-manufacturing-js": () => import("./../../../src/pages/industry/automotive-component-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-automotive-component-manufacturing-js" */),
  "component---src-pages-industry-consumer-durables-js": () => import("./../../../src/pages/industry/consumer-durables.js" /* webpackChunkName: "component---src-pages-industry-consumer-durables-js" */),
  "component---src-pages-industry-hardware-startups-component-manufacturing-js": () => import("./../../../src/pages/industry/hardware-startups-component-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-hardware-startups-component-manufacturing-js" */),
  "component---src-pages-industry-industrial-machinery-component-manufacturing-js": () => import("./../../../src/pages/industry/industrial-machinery-component-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-industrial-machinery-component-manufacturing-js" */),
  "component---src-pages-industry-medical-equipment-and-devices-manufacturing-js": () => import("./../../../src/pages/industry/medical-equipment-and-devices-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-medical-equipment-and-devices-manufacturing-js" */),
  "component---src-pages-industry-telecommunication-equipment-manufacturing-js": () => import("./../../../src/pages/industry/telecommunication-equipment-manufacturing.js" /* webpackChunkName: "component---src-pages-industry-telecommunication-equipment-manufacturing-js" */),
  "component---src-pages-partner-network-js": () => import("./../../../src/pages/partner-network.js" /* webpackChunkName: "component---src-pages-partner-network-js" */),
  "component---src-pages-quality-assurance-js": () => import("./../../../src/pages/quality-assurance.js" /* webpackChunkName: "component---src-pages-quality-assurance-js" */),
  "component---src-pages-service-casting-services-js": () => import("./../../../src/pages/service/casting-services.js" /* webpackChunkName: "component---src-pages-service-casting-services-js" */),
  "component---src-pages-service-cnc-machining-services-js": () => import("./../../../src/pages/service/cnc-machining-services.js" /* webpackChunkName: "component---src-pages-service-cnc-machining-services-js" */),
  "component---src-pages-service-metal-fabrication-services-js": () => import("./../../../src/pages/service/metal-fabrication-services.js" /* webpackChunkName: "component---src-pages-service-metal-fabrication-services-js" */),
  "component---src-pages-service-metal-forging-services-js": () => import("./../../../src/pages/service/metal-forging-services.js" /* webpackChunkName: "component---src-pages-service-metal-forging-services-js" */),
  "component---src-pages-service-metal-injection-molding-services-js": () => import("./../../../src/pages/service/metal-injection-molding-services.js" /* webpackChunkName: "component---src-pages-service-metal-injection-molding-services-js" */),
  "component---src-pages-service-metal-stamping-services-js": () => import("./../../../src/pages/service/metal-stamping-services.js" /* webpackChunkName: "component---src-pages-service-metal-stamping-services-js" */),
  "component---src-pages-service-plastic-injection-molding-services-js": () => import("./../../../src/pages/service/plastic-injection-molding-services.js" /* webpackChunkName: "component---src-pages-service-plastic-injection-molding-services-js" */),
  "component---src-pages-service-prototyping-services-js": () => import("./../../../src/pages/service/prototyping-services.js" /* webpackChunkName: "component---src-pages-service-prototyping-services-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-landing-js": () => import("./../../../src/templates/blog-landing.js" /* webpackChunkName: "component---src-templates-blog-landing-js" */)
}

